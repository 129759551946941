import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import FooterDefault from "components/Footers/FooterDefault.js";
import Blogs from "views/sections-sections/Blogs.js";

function LandingPage() {
  const [pills, setPills] = React.useState("1");
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">What is this?</h2>
                <p style={{ fontWeight: 400 }}>
                  Leaving your dog with a dog sitter? There is so much
                  information you want to handover and usually in a small amount
                  of time, document your routine and share it with your sitter
                  so you and your pup don't need to worry
                </p>
              </Col>
            </Row>
            <div className="separator separator-info"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/dogtag.webp") + ")",
                    }}
                  >
                    <p
                      style={{ fontWeight: 400 }}
                      className="blockquote blockquote-info"
                    >
                      "I was always nervous about leaving Cheddar with a sitter
                      and want to know she's as comfortable as at home.. it
                      helps to know the information is easily available"{" "}
                      <br></br>
                      <br></br>
                      <small>-James Nightingale</small>
                    </p>
                  </div>
                  <div
                    className="image-container image-left-bottom"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/dogwalkmap.webp") + ")",
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/dogfeed.webp") + ")",
                    }}
                  ></div>
                  <h3>So why try our platform?</h3>
                  <p style={{ fontWeight: 400 }}>
                    This platform was designed by pawrents, for pawrents! We
                    understand that pets are family and there are so many small
                    details that make up a pet's day. Handing over all of the
                    details of a dogs routine is really difficult during meet
                    and greets and even if you manage to remember to tell them
                    everything, it's unlikely all the details can be remembered.
                  </p>
                  <p style={{ fontWeight: 400 }}>
                    You'll be able to put in all of the details of your doggo's
                    routine, with photos.. even remembering the treat before
                    bed! We've made the pages as clear as possible for the
                    sitter so they can access very important information
                    quickly.. such as the vet's contact number. They'll be able
                    to see all the times for your dog's routine and also any
                    behavioural information you want them to be aware of.
                  </p>
                  <p style={{ fontWeight: 400 }}>
                    We have designed dog tags that link directly to your pet's
                    page via a QR code, this means that it's so easy for the
                    sitter to pull up the information when out on a walk, or in
                    the home. We understand some pupper's are looked after in
                    their home, and so you can also add in any extra details
                    about your house for the sitter too.. all the things you
                    know and live with that someone may not expect!
                  </p>
                  <p style={{ fontWeight: 400 }}>
                    If there's a route you'd like your sitter to avoid, you can
                    add in your dog's favourite walk routes too!
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div
          className="testimonials-1 section-image"
          style={{
            backgroundSize: "auto",
            backgroundImage:
              "url(" + require("assets/img/horsebanner.png") + ")",
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">Why trust us?</h2>
                <h4 className="description text-white">
                  We can show you a bunch of stats to try to convince you, but
                  it's best to hear it from pets who've used the platform
                  successfully...
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/IMG_2027.png")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <p style={{ fontWeight: 400 }}>
                      I always expect a treat before I sleep.. before Pawrent
                      this was missed.. happy to say bedtime treats are back!
                    </p>
                  </CardBody>
                  <div className="icon icon-info">
                    <i className="fa fa-quote-right"></i>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h4">Cheddar</CardTitle>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/ruby.png")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <p style={{ fontWeight: 400 }}>
                      I love being outside and watching our garden, sitter's are
                      now more confident letting me stay out thanks to the
                      advice of my pawrents
                    </p>
                  </CardBody>
                  <div className="icon icon-info">
                    <i className="fa fa-quote-right"></i>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h4">Ruby</CardTitle>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/IMG_2799.png")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <p style={{ fontWeight: 400 }}>
                      I need to be on a lead when going into the garden as I
                      just don't like coming back inside without a run around..
                      now sitter's know this!
                    </p>
                  </CardBody>
                  <div className="icon icon-info">
                    <i className="fa fa-quote-right"></i>
                  </div>
                  <CardFooter>
                    <CardTitle tag="h4">Clover</CardTitle>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="pricing-2">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">Give it a try!</h2>
              </Col>
            </Row>
            <Row>
              <Col md="4"></Col>
              <Col md="4">
                <Card
                  className="card-pricing card-background card-raised"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/dogqr.webp") + ")",
                  }}
                >
                  <CardBody>
                    <CardTitle tag="h1">
                      <small>£</small>
                      10 monthly
                    </CardTitle>
                    <ul>
                      <li>QR code dog tag included</li>
                      <li>Update your doggo details</li>
                      <li>Update your house details</li>
                      <li>Your page will be active on subscription</li>
                    </ul>
                    <Button
                      className="btn-neutral btn-round"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Get Started
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4"></Col>
            </Row>
          </Container>
        </div>
        <div className="section section-contact-us text-center">
          <Container>
            <h2 className="title">Do you still have questions?</h2>
            <p>Please drop us a message and we'll get back to you asap</p>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <InputGroup
                  className={
                    firstFocus ? "input-lg input-group-focus" : "input-lg"
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="First Name..."
                    type="text"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    emailFocus ? "input-lg input-group-focus" : "input-lg"
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email Here..."
                    type="text"
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
                  ></Input>
                </InputGroup>
                <div className="textarea-container">
                  <Input
                    cols="80"
                    name="name"
                    placeholder="Type a message..."
                    rows="4"
                    type="textarea"
                  ></Input>
                </div>
                <div className="send-button">
                  <Button
                    block
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    Send Message
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <FooterDefault />
      </div>
    </>
  );
}

export default LandingPage;
