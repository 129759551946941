import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";

// pages
import AboutUs from "views/examples/AboutUs.js";
import BlogPost from "views/examples/BlogPost.js";
import BlogPosts from "views/examples/BlogPosts.js";
import ContactUs from "views/examples/ContactUs.js";
import Ecommerce from "views/examples/Ecommerce.js";
import Index from "views/Index.js";
import LandingPage from "views/examples/LandingPage.js";
import LoginPage from "views/examples/LoginPage.js";
import NucleoIcons from "views/NucleoIcons.js";
import Presentation from "views/Presentation.js";
import Pricing from "views/examples/Pricing.js";
import ProductPage from "views/examples/ProductPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import Sections from "views/Sections.js";
import SignupPage from "views/examples/SignupPage.js";
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";

const App = () => {
  const [user, setUser] = React.useState(false);
  return (
    <>
      <Routes>
        {/* <Route path="/about-us" element={<AboutUs />} />
      <Route path="/blog-post" element={<BlogPost />} />
      <Route path="/blog-posts" element={<BlogPosts />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/e-commerce" element={<Ecommerce />} />
      <Route path="/index" element={<Index />} /> */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/cheddar" element={<ProfilePage />} />
        {/* <Route path="/login-page" element={<LoginPage />} />
        <Route
          path="/profile-page"
          element={[
            <ScrollTransparentNavbar setUser={setUser} user={user} />,
            <ProfilePage user={user} setUser={setUser} />,
          ]}
        /> */}
        {/* <Route path="/nucleo-icons" element={<NucleoIcons />} />
      <Route path="/presentation" element={<Presentation />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/product-page" element={<ProductPage />} />
      <Route path="/profile-page" element={<ProfilePage />} />
      <Route path="/sections" element={<Sections />} />
      <Route path="/sign-up" element={<SignupPage />} />  */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

export default App;
