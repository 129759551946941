import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import FooterDefault from "components/Footers/FooterDefault.js";

function ProfilePage() {
  const [pills, setPills] = React.useState("1");
  const [iconTabs, setIconTabs] = React.useState("1");

  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [collapses, setCollapses] = React.useState([1]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <ProfilePageHeader />
        <div className="section">
          <Container>
            <div className="button-container">
              <Button
                className="btn-round mr-1"
                color="info"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  const linkElement = document.createElement("a");
                  linkElement.href = "tel:+447412505576";
                  linkElement.click();
                }}
                size="lg"
              >
                <i className="fa fa-phone"></i>
                <br />
                Owner
              </Button>
              <Button
                className="btn-round mr-1"
                color="danger"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  const linkElement = document.createElement("a");
                  linkElement.href = "tel:+44115 966 7920";
                  linkElement.click();
                }}
                size="lg"
              >
                <i className="fa fa-phone"></i>
                <br />
                My Vet
              </Button>
              {/* <Button
                className="btn-round btn-icon mr-1"
                color="default"
                href="#pablo"
                id="tooltip871723210"
                onClick={(e) => e.preventDefault()}
                size="lg"
              >
                <i className="fab fa-twitter"></i>
              </Button> */}
              {/* <UncontrolledTooltip delay={0} target="tooltip871723210">
                Follow me on Twitter
              </UncontrolledTooltip>
              <Button
                className="btn-round btn-icon"
                color="default"
                href="#pablo"
                id="tooltip259363830"
                onClick={(e) => e.preventDefault()}
                size="lg"
              >
                <i className="fab fa-instagram"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip259363830">
                Follow me on Instagram
              </UncontrolledTooltip> */}
            </div>
            {/* <h3 className="title">About me</h3>
            <h5 className="description text-center">
              An artist of considerable range, Ryan — the name taken by
              Melbourne-raised, Brooklyn-based Nick Murphy — writes, performs
              and records all of his own music, giving it a warm, intimate feel
              with a solid groove structure. An artist of considerable range.
            </h5> */}
            <Row>
              <Col md="12">
                {/* <h4 className="title text-center">My Portfolio</h4> */}
                <div className="nav-align-center">
                  <Nav
                    className="nav-pills-info nav-pills-just-icons"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("1");
                        }}
                        className={pills === "1" ? "active" : ""}
                        role="tablist"
                        href="#pablo"
                      >
                        <i className="fa fa-paw"></i>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("2");
                        }}
                        className={pills === "2" ? "active" : ""}
                        role="tablist"
                        href="#pablo"
                      >
                        <i className="fa fa-home"></i>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <TabContent className="gallery" activeTab={"pills" + pills}>
                  <TabPane tabId="pills1">
                    <Row>
                      <Col className="mr-auto ml-auto" lg="6">
                        <Card>
                          <CardHeader>
                            <Nav
                              className="nav-pills-info nav-pills-icons"
                              pills
                              role="tablist"
                            >
                              <NavItem>
                                <NavLink
                                  className={iconTabs === "1" ? "active" : ""}
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIconTabs("1");
                                  }}
                                >
                                  <i className="fa fa-calendar"></i>
                                  Morning
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={iconTabs === "2" ? "active" : ""}
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIconTabs("2");
                                  }}
                                >
                                  <i className="fa fa-calendar"></i>
                                  Evening
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={iconTabs === "3" ? "active" : ""}
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIconTabs("3");
                                  }}
                                >
                                  <i className="fa fa-map-signs"></i>
                                  Walks
                                </NavLink>
                              </NavItem>
                              {/* <NavItem>
                                <NavLink
                                  className={iconTabs === "4" ? "active" : ""}
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIconTabs("4");
                                  }}
                                >
                                  <i className="fa fa-bone"></i>
                                  Info
                                </NavLink>
                              </NavItem> */}
                            </Nav>
                          </CardHeader>
                          <CardBody>
                            <TabContent
                              className="text-left"
                              activeTab={"iconTabs" + iconTabs}
                            >
                              <TabPane tabId="iconTabs1">
                                <h5
                                  className="bg-info text-center"
                                  style={{
                                    color: "white",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                  }}
                                >
                                  We usually wake up and go to Cheddar anytime
                                  between 7-9am. Cheddar will stay in her crate
                                  until you wake up.
                                </h5>
                                <ol>
                                  <li>
                                    Let her out of her crate, she'll be very
                                    happy to see you so make sure you give her
                                    some love.
                                  </li>
                                  <li>
                                    Go outside for pee & poo via the kitchen
                                    door and go into the garden with her.
                                  </li>
                                  <li>
                                    She might need some encouragement to poo if
                                    she doesn't. Just tell her "Go poo" multiple
                                    times.
                                  </li>
                                  <li>
                                    Her breakfast is in the left cupboard in the
                                    pantry on top of the microwave.
                                  </li>
                                  <li>
                                    One cup full of food (not heaped) in her
                                    black slow eating bowl, with a little bit of
                                    cold water mixed in.
                                  </li>
                                  <li>
                                    She eats in her crate, so she'll run in and
                                    wait for you to put the bowl down and tell
                                    her to eat.
                                  </li>
                                  <li>
                                    Tell her to "wait" and trigger word "go" to
                                    eat.
                                  </li>
                                  <li>
                                    <b>
                                      She sometimes gets excited, but make sure
                                      not to play with her after eating as she
                                      needs an hour to let it settle.
                                    </b>
                                  </li>
                                  <li>
                                    We usually take her for a walk around
                                    midday, or early afternoon, see the walks
                                    section for recommendations.
                                  </li>
                                </ol>
                              </TabPane>
                              <TabPane tabId="iconTabs2">
                                <h5
                                  className="bg-info text-center"
                                  style={{
                                    color: "white",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                  }}
                                >
                                  We feed her in the evening at around 7pm.. so
                                  she may stare at you around this time to
                                  remind you
                                </h5>
                                <ol>
                                  <li>
                                    Evening food is the same as breakfast, one
                                    cup of kibble with some water in the crate
                                  </li>
                                  <li>
                                    At bed time, take her out the back door for
                                    another pee and poo.. again she may need
                                    encouragement for a poo but she should do
                                    before bed
                                  </li>
                                  <li>
                                    As it's dark, you may need to use your phone
                                    torch heading out, but there is a security
                                    light in the garden that should activate
                                    when you walk towards the patio area.
                                  </li>
                                  <li>
                                    She knows the word "inside" to get into her
                                    crate for bed
                                  </li>
                                  <li>
                                    Make sure to put her some fresh water in the
                                    crate bowl before bed.
                                  </li>
                                  <li>
                                    Very important, she always has a small treat
                                    before we lock the crate for the night,
                                    these are in the pantry above the coffee
                                    machine (Lily's kitchen)
                                  </li>
                                  <li>
                                    She'll usually have a drink of water and
                                    then settle down for the night.{" "}
                                    <b>
                                      Sometimes she drinks too intensely and
                                      chokes a bit so make sure you keep an eye
                                      on her while she is doing that.
                                    </b>
                                  </li>
                                  <li>
                                    Before you go to bed, close the living room
                                    door, the pantry door, the office door and
                                    the kitchen door so it keeps the hallway
                                    nice and warm for Cheddar in the night
                                  </li>
                                </ol>
                              </TabPane>
                              <TabPane tabId="iconTabs3">
                                <div
                                  aria-multiselectable={true}
                                  className="card-collapse"
                                  id="accordion"
                                  role="tablist"
                                >
                                  <Card className="card-plain">
                                    <CardHeader id="headingOne" role="tab">
                                      <a
                                        aria-expanded={collapses.includes(1)}
                                        data-toggle="collapse"
                                        href="#pablo"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          changeCollapse(1);
                                        }}
                                      >
                                        <b>Prep </b>
                                        <i className="now-ui-icons arrows-1_minimal-down"></i>
                                      </a>
                                    </CardHeader>
                                    <Collapse isOpen={collapses.includes(1)}>
                                      <CardBody>
                                        <h5
                                          className="bg-info text-center"
                                          style={{
                                            color: "white",
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                          }}
                                        >
                                          All of Cheddar's walk stuff are
                                          hanging in the pantry under the
                                          drinks.
                                        </h5>
                                        <ol>
                                          <li>
                                            Wear Cheddar's harness by putting it
                                            on the floor and asking her to give
                                            you one "Paw" at a time
                                          </li>
                                          <li>
                                            The harness contains a tracker on
                                            it. Hold down the metal button until
                                            you see a green light to turn it on
                                            and hold down the button until you
                                            see red to turn it off.
                                          </li>
                                          <li>Wear Cheddar's collar.</li>
                                          <li>
                                            Leads
                                            <ul>
                                              <li>
                                                {" "}
                                                She has a black super long one
                                                (8m) for when she goes to places
                                                like Colwick{" "}
                                              </li>
                                              <li>
                                                {" "}
                                                A short one with a seatbelt
                                                attachment that you can use for
                                                walks in places with cars{" "}
                                              </li>
                                              <li>
                                                {" "}
                                                One that wraps around the waist
                                                for a hands free experience{" "}
                                              </li>
                                            </ul>
                                            <li>
                                              She has a rainbow coat for rainy
                                              weather as she gets muddy due to
                                              ther short height. It can go over
                                              her harness( it has holes for the
                                              harness).
                                            </li>
                                          </li>
                                        </ol>
                                      </CardBody>
                                    </Collapse>
                                    <CardHeader id="headingTwo" role="tab">
                                      <a
                                        aria-expanded={collapses.includes(2)}
                                        data-toggle="collapse"
                                        href="#pablo"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          changeCollapse(2);
                                        }}
                                      >
                                        <b>Locations </b>
                                        <i className="now-ui-icons arrows-1_minimal-down"></i>
                                      </a>
                                    </CardHeader>
                                    <Collapse isOpen={collapses.includes(2)}>
                                      <CardBody>
                                        <ol>
                                          <li>
                                            We tend to mostly take Cheddar to
                                            Colwick park.
                                            <a href="https://g.co/kgs/Tsf2Mis">
                                              Wired On Wheels
                                            </a>{" "}
                                            for a coffee.
                                            <iframe
                                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1158.3767038256076!2d-1.1048140086136382!3d52.94668024698123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879c3fcad05807b%3A0x3fd740c98ec75647!2sColwick%20Country%20Park!5e1!3m2!1sen!2suk!4v1708794813430!5m2!1sen!2suk"
                                              title="Colwick map"
                                              width="100%"
                                              height="auto"
                                              style={{ border: 0 }}
                                              allowfullscreen=""
                                              loading="lazy"
                                              referrerpolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                            We tend to do a big loop around the
                                            big lake
                                            <img
                                              alt="colwick loop"
                                              width="100%"
                                              height="auto"
                                              src={require("assets/img/static_map.webp")}
                                            ></img>
                                          </li>
                                          <li>
                                            Gedling Park
                                            <iframe
                                              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9605.865020469657!2d-1.0855793!3d52.9940003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879c6da1773fdad%3A0x2cfe02f4b06c9ed3!2sGedling%20Country%20Park!5e0!3m2!1sen!2suk!4v1708808409563!5m2!1sen!2suk"
                                              title="Gedling Park"
                                              width="100%"
                                              height="auto"
                                              style={{ border: 0 }}
                                              allowfullscreen=""
                                              loading="lazy"
                                              referrerpolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                          </li>
                                          <li>
                                            Around the neighborhood at the park
                                            behind our house. We tend to avoid
                                            the roads
                                            <iframe
                                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2403.0253167349842!2d-1.1049460236638333!3d52.965958972181454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4879c13b86bb1eab%3A0x524e3b5568516767!2sCarlton%20Hill%20Recreation%20Ground!5e0!3m2!1sen!2suk!4v1708808542595!5m2!1sen!2suk"
                                              title="Carlton"
                                              width="100%"
                                              height="auto"
                                              style={{ border: 0 }}
                                              allowfullscreen=""
                                              loading="lazy"
                                              referrerpolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                          </li>
                                        </ol>
                                      </CardBody>
                                    </Collapse>
                                    <CardHeader id="headingThree" role="tab">
                                      <a
                                        aria-expanded={collapses.includes(3)}
                                        data-toggle="collapse"
                                        href="#pablo"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          changeCollapse(3);
                                        }}
                                      >
                                        <b>Attitude </b>
                                        <i className="now-ui-icons arrows-1_minimal-down"></i>
                                      </a>
                                    </CardHeader>
                                    <Collapse isOpen={collapses.includes(3)}>
                                      <CardBody>
                                        <ol>
                                          <li>
                                            Keep Cheddar on the lead during the
                                            walk
                                          </li>
                                          <li>
                                            Some dogs she gets along well with,
                                            but she goes straight into play mode
                                            and runs around which is a challenge
                                            on the lead
                                          </li>
                                          <li>
                                            She can be a bit snappy towards
                                            other dogs but doesn't bite.. it's
                                            just a corgi thing!
                                          </li>
                                          <li>
                                            <b>
                                              Keep an eye out for any food when
                                              walking, she will eat everything
                                              and anything! So we try to look
                                              ahead to make sure she doesn't eat
                                              anything she shouldn't
                                            </b>
                                          </li>
                                        </ol>
                                      </CardBody>
                                    </Collapse>
                                  </Card>
                                </div>
                              </TabPane>
                              {/* <TabPane tabId="iconTabs4">
                                <div
                                  aria-multiselectable={true}
                                  className="card-collapse"
                                  id="accordion"
                                  role="tablist"
                                >
                                  <p>More info for Cheddar</p>
                                </div>
                              </TabPane> */}
                            </TabContent>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="pills2">
                    <Row>
                      <Col className="mr-auto ml-auto" lg="6">
                        <Card>
                          <CardHeader>
                            <Nav
                              className="nav-pills-info nav-pills-icons"
                              pills
                              role="tablist"
                            >
                              <NavItem>
                                <NavLink
                                  className={iconTabs === "1" ? "active" : ""}
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIconTabs("1");
                                  }}
                                >
                                  <i className="fa fa-tv"></i>
                                  Tech
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={iconTabs === "2" ? "active" : ""}
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIconTabs("2");
                                  }}
                                >
                                  <i className="fa fa-calendar"></i>
                                  Morning
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={iconTabs === "3" ? "active" : ""}
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIconTabs("3");
                                  }}
                                >
                                  <i className="fa fa-calendar"></i>
                                  Evening
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </CardHeader>
                          <CardBody>
                            <TabContent
                              className="text-left"
                              activeTab={"iconTabs" + iconTabs}
                            >
                              <TabPane tabId="iconTabs1">
                                <div
                                  aria-multiselectable={true}
                                  className="card-collapse"
                                  id="accordion"
                                  role="tablist"
                                >
                                  <Card className="card-plain">
                                    <CardHeader id="headingOne" role="tab">
                                      <a
                                        aria-expanded={collapses.includes(1)}
                                        data-toggle="collapse"
                                        href="#pablo"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          changeCollapse(1);
                                        }}
                                      >
                                        <b>WiFi</b>
                                        <i className="now-ui-icons arrows-1_minimal-down"></i>
                                      </a>
                                    </CardHeader>
                                    <Collapse isOpen={collapses.includes(1)}>
                                      <CardBody>
                                        <li>
                                          Main WiFi is "Jurassic Park Guest
                                          WiFi"
                                        </li>
                                        <li>
                                          Repeater is "Site B" (better signal
                                          for upstairs etc.){" "}
                                        </li>
                                        <li>
                                          Password for both is: MonkeyNuts666
                                        </li>
                                      </CardBody>
                                    </Collapse>
                                  </Card>
                                  <Card className="card-plain">
                                    <CardHeader id="headingTwo" role="tab">
                                      <a
                                        aria-expanded={collapses.includes(2)}
                                        data-toggle="collapse"
                                        href="#pablo"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          changeCollapse(2);
                                        }}
                                      >
                                        <b>TV </b>
                                        <i className="now-ui-icons arrows-1_minimal-down"></i>
                                      </a>
                                    </CardHeader>
                                    <Collapse isOpen={collapses.includes(2)}>
                                      <CardBody>
                                        We use the FireTV stick for everything
                                        and don't have normal TV/SKY TV etc.
                                        <br /> <br />
                                        When you turn on the TV, the FireTV
                                        stick should turn on with it, and it
                                        should load on screen.. if not, you can
                                        use the Sony Amp remote and click
                                        "Sat/CATV" and it should appear.
                                        Sometimes it appears to have a green
                                        tint.. if this is the case, switch the
                                        tv off wait a few mins and switch it
                                        back on again and it should be okay!
                                        <br /> <br />
                                        All of the usual apps are installed and
                                        logged in, we have Netflix, Prime, Apple
                                        TV, you're welcome to use them all
                                      </CardBody>
                                    </Collapse>
                                  </Card>
                                  <Card className="card-plain">
                                    <CardHeader id="headingThree" role="tab">
                                      <a
                                        aria-expanded={collapses.includes(3)}
                                        data-toggle="collapse"
                                        href="#pablo"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          changeCollapse(3);
                                        }}
                                      >
                                        <b>Heating </b>
                                        <i className="now-ui-icons arrows-1_minimal-down"></i>
                                      </a>
                                    </CardHeader>
                                    <Collapse isOpen={collapses.includes(3)}>
                                      <CardBody>
                                        The thermostat is in the hallway next to
                                        the pantry door, it's all controlled
                                        automatically and should keep the house
                                        nice and warm, but it's there if you
                                        need it
                                      </CardBody>
                                    </Collapse>
                                  </Card>
                                  <Card className="card-plain">
                                    <CardHeader id="headingFour" role="tab">
                                      <a
                                        aria-expanded={collapses.includes(4)}
                                        data-toggle="collapse"
                                        href="#pablo"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          changeCollapse(4);
                                        }}
                                      >
                                        <b>Lights </b>
                                        <i className="now-ui-icons arrows-1_minimal-down"></i>
                                      </a>
                                    </CardHeader>
                                    <Collapse isOpen={collapses.includes(4)}>
                                      <CardBody>
                                        Cheddar's Hallway light is automatic and
                                        comes on in the evening and turns off in
                                        the morning, we leave this light on
                                        overnight for Cheddar
                                        <br />
                                        <br />
                                        The living room lights are all
                                        controlled by voice, you can use google
                                        home in the living room or the google
                                        tablet in the kitchen to say commands.
                                        <br />
                                        <br />
                                        <ol>
                                          <li>
                                            Google home is on top of the speaker
                                            in the lounge and usually off at the
                                            plug, so switch the plugs on wait
                                            for google to boot and then you can
                                            sort the lights.
                                          </li>
                                          <li>
                                            "Hey Google, turn on living room"
                                            turns on all of the living room
                                            lights
                                          </li>
                                          <li>
                                            "Hey Google, turn off living room"
                                            turns off all of the living room
                                            lights
                                          </li>
                                          <li>
                                            "Hey Google, turn on wall" turns on
                                            the wall lights
                                          </li>
                                          <li>
                                            "Hey Google, turn off wall" turns
                                            off the wall lights
                                          </li>
                                          <li>
                                            "Hey Google, turn off strip" turns
                                            off the light strip behind the tv
                                          </li>
                                          <li>
                                            "Hey Google, set the brightness of
                                            wall to 40%" will see the wall
                                            lights to a lower brightness
                                          </li>
                                        </ol>
                                      </CardBody>
                                    </Collapse>
                                  </Card>
                                  <Card className="card-plain">
                                    <CardHeader id="headingFive" role="tab">
                                      <a
                                        aria-expanded={collapses.includes(5)}
                                        data-toggle="collapse"
                                        href="#pablo"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          changeCollapse(5);
                                        }}
                                      >
                                        <b>Cooking </b>
                                        <i className="now-ui-icons arrows-1_minimal-down"></i>
                                      </a>
                                    </CardHeader>
                                    <Collapse isOpen={collapses.includes(5)}>
                                      <CardBody>
                                        The oven is an induction oven, as are
                                        all of the pots/pans. The hob is very
                                        quick to heat up the pans. There are
                                        also 2 ovens, we always use the one on
                                        the right, the right hand dial sets the
                                        temperature and it's just a fan oven.
                                        <br />
                                        <br />
                                        The air fryer is our preferred method
                                        for cooking over the oven as it's just
                                        much faster and easier, it can do any
                                        veg and protein. It'll cook a chicken
                                        breast in 20 minutes, on air fry mode,
                                        flipping it after 10 minutes for
                                        example. You can cook in both drawers of
                                        the air fryer at the same time, and
                                        don't need to worry about cleaning it
                                        between cooks.
                                        <br />
                                        <br />
                                        The kettle is also an induction kettle,
                                        so fill as normal and place on the
                                        centre hob
                                      </CardBody>
                                    </Collapse>
                                  </Card>
                                </div>
                              </TabPane>
                              <TabPane tabId="iconTabs2">
                                <ol>
                                  <li>Open the living room door for Cheddar</li>
                                  <li>
                                    Open the balcony door curtains for Cheddar
                                    (She likes to keep watch!)
                                  </li>
                                </ol>
                              </TabPane>
                              <TabPane tabId="iconTabs3">
                                <ol>
                                  <li>
                                    Make sure all the lights are off apart from
                                    Cheddar's hallway light
                                  </li>
                                  <li>
                                    Lock the kitchen door and the front door and
                                    all windows closed
                                  </li>
                                  <li>
                                    Turn off the air fryer plugs and the oven
                                    (red) switch in the kitchen
                                  </li>
                                  <li>
                                    Close the kitchen door and pantry door and
                                    also the office door as this keeps hallway
                                    warm for Cheddar
                                  </li>
                                  <li>
                                    Close the living room balcony door curtains
                                    and make sure the balcony doors are locked
                                  </li>
                                  <li>
                                    The TV unit has an extension on the midle
                                    shelf next to the centre speaker, it has a
                                    switch on the extension that we use to turn
                                    all the AV equipment off
                                  </li>
                                  <li>
                                    Turn off the living room lights via google
                                    (more info in the Tech section for the
                                    google commands)
                                  </li>
                                  <li>
                                    Switch off the plug sockets next to the sofa
                                    for google and the extension
                                  </li>
                                  <li>Close the living room door</li>
                                </ol>
                              </TabPane>
                            </TabContent>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
        <FooterDefault />
      </div>
    </>
  );
}

export default ProfilePage;
